/* i18n语言包（越南语） */

export default {
  'sys.operator.success': 'Hoạt động thành công',
  '业务异常': 'Kinh doanh bất thường',
  '网络请求失败，请稍候再试': 'Yêu cầu mạng không thành công, vui lòng thử lại trong thời gian chờ',
  '您好！': 'Xin chào!',
  '欢迎来到license！': 'Chào mừng đến với License!',
  '请输入公司关键词': 'Vui lòng nhập từ khóa công ty',
  '公司名不能为空': 'Tên công ty không được để trống',
  '请输入用户名': 'Vui lòng nhập tên người dùng',
  '用户名不能为空': 'Tên người dùng không được để trống',
  '请输入密码': 'Vui lòng nhập mật khẩu',
  '密码不能为空': 'Mật khẩu không được để trống',
  '登录': 'Đăng nhập',
  '退出登录': 'Thoát Đăng nhập',
  '登录成功': 'Đăng nhập thành công',
  '暂无权限，请联系后台管理员': 'Không có quyền, vui lòng liên hệ với quản trị viên',
  '向右滑动完成拼图': 'Trượt sang phải để hoàn thành câu đố',
  '刷新': 'Làm mới',
  '抱歉！': 'Xin lỗi!',
  '当前页面不存在...': 'Trang hiện tại không tồn tại...',
  '请检查您输入的网址是否正确，或点击下面的按钮返回首页。': 'Vui lòng kiểm tra xem địa chỉ web bạn đã nhập có chính xác không hoặc nhấp vào nút bên dưới để quay lại trang chủ.',
  '登录状态已过期，请重新登录!': 'Trạng thái đăng nhập đã hết hạn, vui lòng đăng nhập lại!',
  '返回首页': 'Quay lại trang đầu',
  '关闭其他': 'Đóng khác', 
  '关闭左侧': 'Đóng trái', 
  '关闭右侧': 'Đóng bên phải', 
  '关闭全部': 'Đóng tất cả',
  '搜索': 'Tìm kiếm',
  '清空': 'Trống rỗng',
  '添加': 'Thêm',
  '删除': 'Xoá',
  '批量删除': 'Xóa hàng loạt',
  '启用': 'Bật',
  '禁用': 'Tắt',
  '编辑': 'Chỉnh sửa',
  '修改': 'Sửa đổi',
  '审核': 'Đánh giá',
  '反审核': 'Chống kiểm toán',
  '条码': 'Mã vạch',
  '详情': 'Chi tiết',
  '导出Excel': 'Xuất Excel',
  '边框': 'Viền',
  '大': 'Lớn',
  '中': 'Giữa',
  '小': 'Nhỏ',
  '许可证书管理': 'Quản lý giấy phép', 
  '许可证书': 'Giấy phép',
  '请输入许可证名称': 'Vui lòng nhập tên giấy phép',
  '系统管理': 'Quản lý hệ thống', 
  '菜单管理': 'Quản lý menu',
  '开始日期': 'Ngày bắt đầu',
  '至': 'Đến',
  '结束日期': 'Ngày kết thúc',
  'ID或菜单名称': 'ID hoặc tên menu',
  '请输入权限值': 'Vui lòng nhập giá trị quyền',
  '菜单名称': 'Tên thực đơn',
  '图标': 'Biểu tượng',
  '选择图标': 'Chọn biểu tượng',
  '组件路径': 'Đường dẫn thành phần',
  '类型': 'Loại',
  '排序': 'Sắp xếp',
  '目录': 'Thư mục',
  '菜单': 'Thực đơn',
  '按钮': 'Nút bấm',
  '未选中任何行': 'Chưa chọn dòng nào',
  '操作成功': 'Hoạt động thành công',
  '确认要启用选中的': 'Xác nhận rằng bạn muốn bật lựa chọn',
  '确认要禁用选中的': 'Xác nhận rằng bạn muốn vô hiệu hóa lựa chọn',
  '确认要删除选中的': 'Xác nhận rằng bạn muốn xóa',
  '条数据？': 'mục dữ liệu đã chọn?',
  '取消': 'Hủy bỏ',
  '确定': 'Xác định',
  '添加菜单': 'Thêm menu',
  '编辑菜单': 'Chỉnh sửa menu',
  '上级菜单': 'Thực đơn cao hơn',
  '请选择上级菜单': 'Vui lòng chọn menu cao hơn',
  '菜单类型': 'Loại menu',
  '菜单图标': 'Biểu tượng menu',
  '图标选择器': 'Bộ chọn biểu tượng',
  '显示排序': 'Hiện sắp xếp',
  '路由地址': 'Địa chỉ định tuyến',
  '组件地址': 'Địa chỉ thành phần',
  '路由重定向': 'Chuyển hướng',
  '权限标识': 'Nhận dạng quyền',
  '菜单状态': 'Trạng thái menu',
  '是否隐藏': 'Ẩn hay không',
  '请输入菜单名称': 'Vui lòng nhập tên thực đơn',
  '请输入排序': 'Vui lòng nhập sắp xếp',
  '请输入路由地址': 'Vui lòng nhập địa chỉ route',
  '请输入组件地址': 'Vui lòng nhập địa chỉ thành phần',
  '请输入路由重定向': 'Vui lòng nhập route redirect',
  '请输入权限标识': 'Vui lòng nhập nhận dạng quyền',
  '显示': 'Hiển thị',
  '隐藏': 'Ẩn',
  '添加子项': 'Thêm mục con',
  '添加翻译': 'Thêm bản dịch',
  '确认删除名称为': 'Bạn có chắc chắn muốn xóa mục dữ liệu có tên',
  '的数据项?': '?',
  '语言类型': 'Loại ngôn ngữ',
  '翻译内容': 'Dịch nội dung',
  '请先选中某一数据行进行操作': 'Vui lòng chọn một dòng dữ liệu để hoạt động',
  '请选择语言类型': 'Vui lòng chọn loại ngôn ngữ',
  '请输入翻译内容': 'Vui lòng nhập nội dung dịch',
  '确认要删除当前项吗？': 'Xác nhận bạn muốn xóa mục hiện tại?',
  '确认要删除选中项吗？': 'Xác nhận rằng bạn muốn loại bỏ lựa chọn?',
  '操作': 'Hoạt động',
  '角色管理': 'Quản lý vai trò',
  '角色编号': 'Mã hóa ký tự',
  '角色名称': 'Tên nhân vật',
  '角色编码': 'Mã hóa ký tự',
  '角色描述': 'Mô tả vai trò',
  '请输入角色编码': 'Vui lòng nhập mã ký tự',
  '请输入角色名称': 'Vui lòng nhập tên nhân vật',
  '请输入描述': 'Vui lòng nhập mô tả',
  '描述': 'Mô tả',
  '添加角色': 'Thêm nhân vật',
  '编辑角色': 'Vai trò biên tập',
  '权限设置': 'Thiết lập quyền',
  '保存权限设置': 'Lưu thiết lập quyền',
  '用户管理': 'Quản lý người dùng',
  '请选择角色': 'Vui lòng chọn nhân vật',
  '请选择部门': 'Vui lòng chọn bộ phận',
  '用户编号': 'Mã hóa người dùng',
  '用户头像': 'Avatar người dùng',
  '账号': 'Số tài khoản',
  '昵称': 'Biệt danh',
  '角色': 'Vai trò',
  '部门': 'Trang chủ',
  '手机号码': 'Số điện thoại',
  '邮箱': 'Hộp thư',
  '状态': 'Trạng thái',
  '账户': 'Tài khoản',
  '姓名': 'Tên',
  '密码': 'Mật khẩu',
  '确认密码': 'Xác nhận mật khẩu',
  '性别': 'Giới tính',
  '男': 'Nam',
  '女': 'Phụ nữ',
  '职务': 'Chức vụ',
  '员工编号': 'Mã nhân viên',
  '出生日期': 'Ngày sinh',
  '用户状态': 'Trạng thái người dùng',
  '请输入账户': 'Vui lòng nhập tài khoản',
  '请输入姓名': 'Vui lòng nhập tên',
  '请输入确认密码': 'Vui lòng nhập mật khẩu xác nhận',
  '请输入员工编号': 'Vui lòng nhập mã nhân viên',
  '添加用户': 'Thêm người dùng',
  '编辑用户': 'Sửa người dùng',
  '请输入手机号码': 'Vui lòng nhập số điện thoại di động',
  '请输入邮箱地址': 'Vui lòng nhập địa chỉ hộp thư',
  '请选择职务': 'Vui lòng chọn vị trí',
  '请选择出生日期': 'Vui lòng chọn ngày sinh',
  '两次输入的密码不一致': 'Mật khẩu nhập hai lần không phù hợp',
  '长度在 6 到 20 个字符': 'Độ dài từ 6 đến 20 ký tự',
  '重置密码': 'Đặt lại mật khẩu',
  '部门管理': 'Quản lý bộ phận',
  '添加部门': 'Thêm bộ phận',
  '编辑部门': 'Phòng biên tập',
  '部门名称': 'Tên bộ phận',
  '部门编号': 'Bộ mã hóa',
  '部门负责人': 'Trưởng phòng',
  '上级部门': 'Bộ phận cao hơn',
  '负责人': 'Trang chủ',
  '请选择上级部门': 'Vui lòng chọn bộ phận cao hơn',
  '请输入部门名称': 'Vui lòng nhập tên bộ phận',
  '职务管理': 'Quản lý công việc',
  '部门职务管理': 'Quản lý chức vụ bộ phận',
  '请选择部门负责人': 'Vui lòng chọn Trưởng phòng',
  '选择负责人': 'Chọn người chịu trách nhiệm',
  '返回': 'Quay lại',
  '职务详情列表': 'Danh sách chi tiết công việc',
  '职务名称': 'Tên công việc',
  '创建人': 'Tạo bởi',
  '创建人ID': 'Tạo ID',
  '创建时间': 'Thời gian tạo',
  '更新人': 'Cập nhật bởi',
  '更新人ID': 'Cập nhật ID',
  '更新时间': 'Thời gian cập nhật',
  '请输入职务名称': 'Vui lòng nhập tên công việc',
  '添加职务': 'Thêm bài viết',
  '编辑职务': 'Chức vụ biên tập',
  '字典管理': 'Quản lý từ điển',
  '字典编号或名称': 'Số từ điển hoặc tên',
  '添加字典': 'Thêm từ điển',
  '编辑字典': 'Sửa từ điển',
  '字典编号': 'Mã hóa từ điển',
  '字典名称': 'Từ điển Name',
  '选项名称': 'Tùy chọnName',
  '选项值': 'Giá trị tùy chọn',
  '请输入字典编号': 'Vui lòng nhập mã hóa từ điển',
  '请输入字典名称': 'Vui lòng nhập tên từ điển',
  '请输入选项值': 'Vui lòng nhập giá trị tùy chọn',
  '请输入选项名称': 'Vui lòng nhập tên tùy chọn',
  '备注': 'Ghi chú',
  '请输入备注': 'Vui lòng nhập ghi chú',
  '添加字典选项': 'Thêm tùy chọn từ điển',
  '编辑字典选项': 'Tùy chọn sửa từ điển',
  '字典列表': 'Danh sách từ điển',
  '字典选项': 'Tùy chọn từ điển',
  '翻译列表': 'Danh sách dịch',
  '租户管理': 'Quản lý khách thuê',
  '添加租户': 'Thêm đối tượng thuê',
  '配置': 'Cấu hình',
  '序号': 'Số sê-ri',
  '租户logo': 'Logo của người thuê',
  '租户名称': 'Tên đối tượng thuê',
  '租户ID': 'ID đối tượng thuê',
  '联系人': 'Liên hệ',
  '联系电话': 'Số điện thoại',
  '开始时间': 'Thời gian bắt đầu',
  '结束时间': 'Thời gian kết thúc',
  '请输入租户名称': 'Vui lòng nhập tên đối tượng thuê',
  '请输入租户编码': 'Vui lòng nhập mã đối tượng thuê',
  '请输入联系人': 'Vui lòng nhập liên hệ',
  '请输入联系电话': 'Vui lòng nhập số liên lạc',
  '请选择开始时间': 'Vui lòng chọn thời gian bắt đầu',
  '请选择结束时间': 'Vui lòng chọn thời gian kết thúc',
  '租户编码': 'Mã hóa đối tượng thuê',
  '备注：租户默认管理用户：admin，密码为联系电话。': 'Ghi chú Người thuê quản lý admin người dùng theo mặc định với mật khẩu là số điện thoại liên lạc.',
  '公司logo': 'Logo công ty',
  '系统名称': 'Tên hệ thống',
  '域名': 'Tên miền',
  '复杂配置': 'Cấu hình phức tạp',
  '邮件配置': 'Cấu hình thư',
  '看板状态': 'Trạng thái Kanban',
  '请输入系统名称': 'Vui lòng nhập tên hệ thống',
  '请输入域名': 'Vui lòng nhập tên miền',
  'logo裁剪': 'Logo cắt',
  '预览': 'Xem thử',
  '选择图片': 'Chọn ảnh',
  '放大': 'Phóng to',
  '缩小': 'Thu nhỏ',
  '左旋转': 'Xoay trái',
  '右旋转': 'Xoay phải',
  '上传图片': 'Upload ảnh',
  '推荐使用：透明白色logo；尺寸比例：宽200~240px，高57px。': 'Đề nghị sử dụng logo màu trắng trong suốt; Tỷ lệ kích thước rộng 200~240px, cao 57px.',
  '操作日志': 'Nhật ký hoạt động',
  'ID': 'ID',
  'TraceId': 'TraceId',
  '操作人': 'Người vận hành',
  '执行方法': 'Phương pháp thực hiện',
  'IP地址': 'Địa chỉ IP',
  '地区': 'Quận',
  '耗时': 'Thời gian',
  '配置中心': 'Trung tâm cấu hình',
  '客户资料': 'Thông tin khách hàng',
  '请输入客户名称': 'Vui lòng nhập tên khách hàng',
  '锡膏虚拟客户': 'Khách hàng ảo của Solder Paste',
  '胶水虚拟客户': 'Khách hàng ảo Keo',
  '自购料': 'Tự mua nguyên liệu',
  '添加客户': 'Thêm khách hàng',
  '编辑客户': 'Chỉnh sửa khách hàng',
  '导入Excel': 'Nhập Excel',
  '客户编号': 'Mã khách hàng',
  '客户名称': 'Tên khách hàng',
  '客户简称': 'Khách hàng gọi tắt',
  '地址': 'Địa chỉ',
  '是': 'Vâng',
  '否': 'Không',
  '客户地址': 'Địa chỉ khách hàng',
  '详细地址': 'Chi tiết địa chỉ',
  '联系方式': 'Thông tin liên hệ',
  '请选择客户地址': 'Vui lòng chọn địa chỉ khách hàng',
  '请输入客户编号': 'Vui lòng nhập mã khách hàng',
  '请输入客户简称': 'Vui lòng nhập tên khách hàng',
  '请输入详细地址': 'Vui lòng nhập địa chỉ chi tiết',
  '请输入联系方式': 'Vui lòng nhập thông tin liên hệ',
  '上传的文件大小不能超过100MB！': 'Kích thước tập tin tải lên không được vượt quá 100MB!',
  '编辑租户': 'Sửa đối tượng thuê',
  '租户状态': 'Tình trạng thuê nhà',
  '暂无数据': 'Không có dữ liệu',
  '请输入图标名称': 'Vui lòng nhập tên biểu tượng',
  '添加选项': 'Thêm tùy chọn',
  '关键词': 'Từ khóa',
  '租户企业全称': 'Tên đầy đủ của doanh nghiệp cho thuê',
  '系统版本信息': 'Thông tin phiên bản hệ thống',
  '许可证书类型': 'Loại giấy phép',
  '添加许可证书': 'Thêm giấy phép',
  '导入服务器信息': 'Nhập thông tin máy chủ',
  '许可证书序列号': 'Số sê-ri giấy phép',
  '用户数': 'Số lượng user',
  '设备数': 'Số lượng thiết bị',
  '有效期': 'Ngày hết hạn',
  'mac地址': 'Địa chỉ MAC',
  '服务器唯一码': 'Mã máy chủ duy nhất',
  '供应商全称': 'Tên đầy đủ của nhà cung cấp',
  '下载': 'Tải về',
  '下载成功': 'Download thành công',
  '导出成功': 'Xuất thành công',
  '租户': 'Trang chủ',
  '选择租户': 'Chọn đối tượng thuê',
  '请选择租户': 'Vui lòng chọn đối tượng thuê',
  '请输入供应商全称': 'Vui lòng nhập tên đầy đủ của nhà cung cấp',
  '请输入租户企业全称': 'Vui lòng nhập tên đầy đủ của doanh nghiệp thuê nhà',
  '请输入系统版本信息': 'Vui lòng nhập thông tin phiên bản hệ thống',
  '请选择许可证书类型': 'Vui lòng chọn loại giấy phép',
  '请输入mac地址': 'Vui lòng nhập địa chỉ MAC',
  '请输入许可证书序列号': 'Vui lòng nhập số serial giấy phép',
  '请输入用户数': 'Vui lòng nhập số user',
  '请输入设备数': 'Vui lòng nhập số thiết bị',
  '请选择有效期': 'Vui lòng chọn ngày hết hạn',
  '租户编号': 'Số thuê nhà',
  '服务器配置': 'Cấu hình máy phục vụ',
  '添加服务器': 'Thêm máy chủ',
  '编辑服务器': 'Sửa máy phục vụ',
  'cpu内核信息': 'Thông tin hạt nhân CPU',
  '逻辑cpu核数': 'Số lõi CPU logic',
  '物理cpu核数': 'Số lõi CPU vật lý',
  '系统内存信息': 'Thông tin bộ nhớ hệ thống',
  '处理器id': 'Bộ xử lý ID',
  '操作系统厂商': 'Nhà sản xuất hệ điều hành',
  '型号': 'Mô hình',
  '未知': 'Không rõ',
  '请输入cpu内核信息': 'Vui lòng nhập thông tin hạt nhân CPU',
  '请输入逻辑cpu核数': 'Nhập số lõi CPU logic',
  '请输入物理cpu核数': 'Vui lòng nhập số lõi CPU vật lý',
  '请输入系统内存信息': 'Vui lòng nhập thông tin bộ nhớ hệ thống',
  '请输入处理器id': 'Vui lòng nhập ID bộ xử lý',
  '请输入操作系统厂商': 'Vui lòng nhập nhà sản xuất hệ điều hành',
  '请输入型号': 'Vui lòng nhập mẫu',
  '提示：': 'Gợi ý:',
  '一级目录组件地址：': 'Địa chỉ thành phần thư mục cấp 1:',
  '子级目录组件地址：': 'Địa chỉ thành phần thư mục con:',
  '记住账号密码': 'Ghi nhớ mật khẩu tài khoản',
  '租户全称': 'Tên đầy đủ của người thuê',
  '请输入租户全称': 'Vui lòng nhập tên đầy đủ của đối tượng thuê',
  '人员名称': 'Tên nhân viên',
  '添加人员': 'Thêm nhân viên',
  '预警人员名称': 'Tên nhân viên cảnh báo',
  '是否停用': 'Đã ngưng hoạt động chưa',
  '请输入有效的手机号码': 'Vui lòng nhập số điện thoại hợp lệ',
  '添加预警人员': 'Thêm nhân viên cảnh báo',
  '编辑预警人员': 'Chỉnh sửa nhân viên cảnh báo',
  '预警租户名称': 'Tên khách hàng cảnh báo',
  '预警租户编码': 'Mã khách hàng cảnh báo'
}
/*
  注意事项：
    1、中文的 ‘编号’ 翻译为越南语时会变成 ‘数量’ 的意思，所以要用 ‘编码’ 去翻译；
*/